import * as React from 'react'
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { graphql } from "gatsby"
import AllWorkGrid from '../../components/allWorkGrid'

const AllWork = ({ location, data }) => {
  return (
    <Layout 
      scheme="light" 
      location={location}
      pageCategory="work"
    >
      <Seo title="MullenLowe U.S. — All Work" />
      <div className='header-spacer'>
        <AllWorkGrid work={data.allSanityWorkItem.edges}/>
      </div>
    </Layout>
  )
}

export default AllWork

export const query = graphql`
{
  allSanityWorkItem(
    sort: {fields: _createdAt, order: DESC}
    filter: {workType: {nin: "work-awards"}}
    ) {
    edges {
      node {
        id
        title
        allWorkFileType
        allWorkVideo {
          loopingVid {
            asset {
              fileURL
            }
          }
        }
        client {
          name
        }
        hoverFileType
        hoverVideo {
          loopingVid {
            asset {
              fileURL
            }
          }
        }
        slug {
          current
        }
        allWorkImage {
          image {
            ...ImageWithPreview
          }
        }
        allWorkMobileImage {
          image {
            ...ImageWithPreview
          }
        }
      }
    }
  }
}
`