import * as React from "react"
import { useState, useEffect} from "react"
import CheckDevice from './checkMobile'
import { Link } from 'gatsby'
import SanityImage from "gatsby-plugin-sanity-image"
import "@reach/dialog/styles.css"


const AllWorkItem = ({ ...props }) => {
  const [counter, setCounter] = useState(1);
  const [handleClickCheck, setHandleClickCheck] = useState(false);
  const [isMobile, setIsMobile] = useState(CheckDevice);
  const image = props.image || props.mobileImage

  const handleClickChecker = (e) => {
    setCounter(counter + 1);
    if(counter <= 1){
      e.preventDefault();
      setHandleClickCheck(true);
    }
  }
  const animateObject = {
    "one": "circle_one",
    "two": "circle_two",
    "three": "circle_three",
    "four": "circle_four",
    "five": "circle_five"
  }
  var randomProperty = function (obj) {
    var keys = Object.keys(obj);
    return obj[keys[ keys.length * Math.random() << 0]];
  };

  useEffect(() => {
    const handlePageResized = () => {
      setIsMobile(CheckDevice);
    };

    window.addEventListener('resize', handlePageResized);
    return () => {
      window.removeEventListener('resize', handlePageResized);
    };
  },[isMobile]);

  return (
    <>
      <li className={`image-content`} key={props.key}>
            <Link to={`/work/${props.slug}`} onClick={handleClickChecker} onKeyDown={handleClickChecker}  role="button" tabIndex="0">
              <div className={`innerContent ${randomProperty(animateObject)}`}>
                <div className="tileContent">
                  <SanityImage {...image} alt="fpo"></SanityImage>
                  <p className="work-title-link">{props.title}</p>
                 
                </div>
                {handleClickCheck && <h2 className="mobile-work-title">{props.title}</h2>}
              </div>
            </Link>
                    
        </li>    
    </>
  )
}


export default AllWorkItem