import React, { useEffect, useState, useRef } from 'react'
import SanityImage from "gatsby-plugin-sanity-image"
import { Link } from 'gatsby'
import LoopingVid from './loopingVid'
import AllWorkItem from './allWorkItem'
import CheckDevice from './checkMobile'
import gsap from "gsap";


const AllWorkGrid = ({ work }) => {
  const ref = useRef(null);
  const [isMobile, setIsMobile] = useState(CheckDevice);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function isPrime(number) {
    if (number <= 1) {
      return false;
    }
  
    for (let i = 2; i <= Math.floor(Math.sqrt(number)); i++) {
      if (number % i === 0) {
        return false;
      }
    }
  
    return true;
  }

  const animateObject = {
    "one": "circle_one",
    "two": "circle_two",
    "three": "circle_three",
    "four": "circle_four",
    "five": "circle_five"
  }
  
  var randomProperty = function (obj) {
    var keys = Object.keys(obj);
    return obj[keys[ keys.length * Math.random() << 0]];
  };



  useEffect(() => {
    const element = ref.current;
    const imgArr = element.querySelectorAll('.image-content');
    const innerContentList = element.querySelectorAll('.innerContent');
    const tileContentList = element.querySelectorAll('.tileContent');
    const imgArrThrd = element.querySelectorAll('.image-content:nth-child(3n-1)');
    const imgOdd = element.querySelectorAll('.image-content:nth-child(odd)');
    const imgEven = element.querySelectorAll('.image-content:nth-child(even)');
    const lastItem = imgArr[imgArr.length - 1];
    const lastItemPosition = Array.from(imgArr).indexOf(lastItem) + 1;

    const handlePageResized = () => {
      setIsMobile(CheckDevice);
    };

    var itemBoundRect = element.getBoundingClientRect();
    var mouse = {x: 0, y: 0, moved: false};

    if (isPrime(lastItemPosition)) {
      lastItem.classList.add('max-last-item-width');
    }

    element.addEventListener('mousemove', function(e) {
      if(window.innerWidth >= 1024) {
        mouse.moved = true;
        mouse.x = e.clientX - itemBoundRect.left;
        mouse.y = e.clientY - itemBoundRect.top;
      }
    });

    tileContentList.forEach((innerItem) => {
      innerItem.setAttribute('data-lax-speed', getRandomInt(300,600));
    });

    function parallaxIt(target, movement) {
      gsap.to(target, 1.1, {
        x: (mouse.x - itemBoundRect.width / 2) / itemBoundRect.width * movement,
        y: (mouse.y - itemBoundRect.height / 2) / itemBoundRect.height * movement
      });
    }

    gsap.ticker.add( function(){
      if(window.innerWidth >= 1024) {
        if (mouse.moved){    
          tileContentList.forEach((innerItem) => {
            const itemSpeed = innerItem.getAttribute('data-lax-speed');
            parallaxIt(innerItem, -itemSpeed);
          });
        }
        mouse.moved = false;
      }
    });

    
    //mouseenter
    imgArr.forEach(function (img){
      img.addEventListener("mouseenter", function(e){
        this.style.zIndex = "3";
      });
      img.addEventListener("mouseleave", function(e){
        this.style.zIndex = "2";
      });
      img.addEventListener("mouseover", function(e){
        imgArr.forEach(el => el.classList.remove('active'));
        img.classList.add('active');
      });
      if(window.innerWidth >= 1024) { 
        img.style.width = getRandomInt(38,48) + "%";
      } else {
        //img.style.width = getRandomInt(42, 50) + "%";
        img.style.flex = `1 1 ${getRandomInt(42,50)}%`;
      }
      
    });

    //desktop
    if(window.innerWidth >= 1024) { 
      let lag = 0.6 //initial value
      for (var i = 0; i < imgArr.length; ++i) {
        imgArr[i].setAttribute('data-speed', 1);
        imgArr[i].setAttribute('data-lag', i * lag);
      }
    }

    //mobile 
    if(window.innerWidth < 1024 ) {
      let lag = 0.2 //initial value
      for (var m = 0; m < imgArr.length; ++m) {
        imgArr[m].setAttribute('data-speed', 1.1);
        imgArr[m].setAttribute('data-lag', m * lag);
      }
    }
    imgArrThrd.forEach(function(i){
      if(window.innerWidth >= 1024) {
        i.style.flex = `1 1 ${getRandomInt(20,30)}%`;
      }
    });

    imgOdd.forEach(function(i){
      if(window.innerWidth < 1024) {
        i.style.left = `${getRandomInt(0,10)}%`;
        i.style.marginBottom = `${getRandomInt(0,20)}px`;
      }
    });

    imgEven.forEach(function(i){
      if(window.innerWidth < 1024) {
        i.style.right = `${getRandomInt(0,10)}%`;
        i.style.marginTop = `${getRandomInt(-10,50)}px`;
      }
    });

    innerContentList.forEach(function(i){
      if(window.innerWidth >= 1024){
        i.style.animationDuration = getRandomInt(40,50) + "s";
      }
    });

    window.addEventListener('resize', function(){
      handlePageResized();
      itemBoundRect = element.getBoundingClientRect();
    });
    window.addEventListener('orientationchange', handlePageResized);
    window.addEventListener('load', handlePageResized);
    window.addEventListener('reload', handlePageResized);
    window.addEventListener('scroll', function(){
      itemBoundRect = element.getBoundingClientRect();
    })
    
    return () => {
      window.removeEventListener('resize', function() {
        handlePageResized();
        itemBoundRect = element.getBoundingClientRect();
      });
      window.removeEventListener('orientationchange', handlePageResized);
      window.removeEventListener('load', handlePageResized);
      window.removeEventListener('reload', handlePageResized);
      window.addEventListener('scroll', function(){
        itemBoundRect = element.getBoundingClientRect();
      })
    }

  }, []);

  return (
      <section id="all-work" ref={ref}>
        <div className="all-work-wrap">
          <ul className="all-work-items">
          
          {work.map(workItem => {
              if(isMobile) {
                return (
                  <React.Fragment key={workItem.node.id}>
                  { (workItem.node.allWorkMobileImage || workItem.node.allWorkImage) && (
                    <AllWorkItem 
                      className="image-content"
                      key={workItem.node.id}
                      slug={workItem.node.slug.current}
                      image={workItem.node.allWorkImage?.image}
                      mobileImage={workItem.node.allWorkMobileImage?.image}
                      fileType={workItem.node.allWorkFileType}
                      title={workItem.node.title}
                    />
                  )}
                  </React.Fragment>
                )
              } else {
                  if (workItem.node.allWorkFileType === 'video')  {
                    return (
                      <li key={workItem.node.id} className={`image-content all-work-looping-video`}>
                        <Link  to={`/work/${workItem.node.slug.current}`} className={`innerContent ${randomProperty(animateObject)}`}>
                            <div className="tileContent">
                              <LoopingVid 
                              key={workItem.node.id}
                              src={workItem.node.allWorkVideo?.loopingVid.asset.fileURL} 
                              title={workItem.node.title}
                              />
                              
                              <p className="work-title-link">{workItem.node.client.name} - {workItem.node.title}</p>
                            </div>
                        </Link>
                      </li>
                    )
                  } else if (workItem.node.allWorkFileType === 'image') {
                    return (
                      <React.Fragment key={workItem.node.id}>
                      {workItem.node.allWorkImage && (
                        <li className={`image-content`} 
                          key={workItem.node.id} 
                        >
                          <Link  to={`/work/${workItem.node.slug.current}`}>
                            <div className={`innerContent ${randomProperty(animateObject)}`}>
                              <div className="tileContent">
                                <SanityImage {...workItem.node.allWorkImage?.image} alt="fpo"></SanityImage>
                                <p className="work-title-link">{workItem.node.client.name} - {workItem.node.title}</p>
                              </div>
                            </div>
                          </Link>
                        
                        </li>
                      )}
                      </React.Fragment>
                    )
                  }
              }
              return false;
            })
          }
  
          </ul>
        </div>
      </section>
  )
}

export default AllWorkGrid